<template>
  <div>
    <main id="#main-content">
      <slot />
    </main>
  </div>
</template>

<script setup>
const locale = undefined

defineOptions({
  name: 'DefaultLayout',
})
useHead({
  htmlAttrs: {
    lang: locale || 'en',
  },
})
</script>

<style lang="css">
body {
  font-family: "IBM Plex Serif", serif;
  background: #f2f4f8;
}
</style>
